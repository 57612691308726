import { emptySplitApi as api } from "./emptyApi";
export const addTagTypes = [
  "getEcho",
  "getConfig",
  "putConfig",
  "getReservationAdmissionConfig",
  "getEncryptionConfig",
  "getPaymentGatewayConfig",
  "getSetting",
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getEcho: build.query<GetEchoApiResponse, GetEchoApiArg>({
        query: () => ({ url: `/system/echo` }),
        providesTags: ["getEcho"],
      }),
      getConfig: build.query<GetConfigApiResponse, GetConfigApiArg>({
        query: (queryArg) => ({ url: `/system/config/${queryArg.name}` }),
        providesTags: ["getConfig"],
      }),
      putConfig: build.mutation<PutConfigApiResponse, PutConfigApiArg>({
        query: (queryArg) => ({
          url: `/system/config/${queryArg.name}`,
          method: "PUT",
        }),
        invalidatesTags: ["putConfig"],
      }),
      getReservationAdmissionConfig: build.query<
        GetReservationAdmissionConfigApiResponse,
        GetReservationAdmissionConfigApiArg
      >({
        query: (queryArg) => ({
          url: `/system/config/reservationAdmission/${queryArg.name}`,
        }),
        providesTags: ["getReservationAdmissionConfig"],
      }),
      getEncryptionConfig: build.query<
        GetEncryptionConfigApiResponse,
        GetEncryptionConfigApiArg
      >({
        query: (queryArg) => ({
          url: `/system/config/encryption/${queryArg.name}`,
        }),
        providesTags: ["getEncryptionConfig"],
      }),
      getPaymentGatewayConfig: build.query<
        GetPaymentGatewayConfigApiResponse,
        GetPaymentGatewayConfigApiArg
      >({
        query: (queryArg) => ({
          url: `/system/config/paymentGateway/${queryArg.name}`,
        }),
        providesTags: ["getPaymentGatewayConfig"],
      }),
      getSetting: build.query<GetSettingApiResponse, GetSettingApiArg>({
        query: (queryArg) => ({ url: `/system/setting/${queryArg.key}` }),
        providesTags: ["getSetting"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as edgeSystemApi };
export type GetEchoApiResponse = unknown;
export type GetEchoApiArg = void;
export type GetConfigApiResponse = unknown;
export type GetConfigApiArg = {
  name: string;
};
export type PutConfigApiResponse = unknown;
export type PutConfigApiArg = {
  name: string;
};
export type GetReservationAdmissionConfigApiResponse =
  /** status 200  */ StringResponse;
export type GetReservationAdmissionConfigApiArg = {
  name: string;
};
export type GetEncryptionConfigApiResponse = /** status 200  */ StringResponse;
export type GetEncryptionConfigApiArg = {
  name: string;
};
export type GetPaymentGatewayConfigApiResponse =
  /** status 200  */ StringResponse;
export type GetPaymentGatewayConfigApiArg = {
  name: string;
};
export type GetSettingApiResponse = /** status 200  */ SettingResponse;
export type GetSettingApiArg = {
  key: string;
};
export type StringResponse = {
  code?: number;
  messages?: string[];
  result: string;
};
export type SettingResponse = {
  code?: number;
  messages?: string[];
  result: string;
};
export const {
  useGetEchoQuery,
  useGetConfigQuery,
  usePutConfigMutation,
  useGetReservationAdmissionConfigQuery,
  useGetEncryptionConfigQuery,
  useGetPaymentGatewayConfigQuery,
  useGetSettingQuery,
} = injectedRtkApi;

import { emptySplitApi as api } from "./emptyApi";
export const addTagTypes = [
  "getDepartments",
  "getDepartment",
  "updateDepartment",
  "deleteDepartment",
  "createDepartment",
  "getCategories",
  "getCategory",
  "updateCategory",
  "deleteCategory",
  "createCategory",
  "getTaxes",
  "getTax",
  "updateTax",
  "deleteTax",
  "createTax",
  "getRooms",
  "getRoom",
  "updateRoom",
  "deleteRoom",
  "createRoom",
  "updateRoomServices",
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getDepartments: build.query<
        GetDepartmentsApiResponse,
        GetDepartmentsApiArg
      >({
        query: (queryArg) => ({
          url: `/masterCode/departments`,
          params: {
            sortDirection: queryArg.sortDirection,
            keyword: queryArg.keyword,
            sortBy: queryArg.sortBy,
            status: queryArg.status,
          },
        }),
        providesTags: ["getDepartments"],
      }),
      getDepartment: build.query<GetDepartmentApiResponse, GetDepartmentApiArg>(
        {
          query: (queryArg) => ({
            url: `/masterCode/department/${queryArg.id}`,
          }),
          providesTags: ["getDepartment"],
        },
      ),
      updateDepartment: build.mutation<
        UpdateDepartmentApiResponse,
        UpdateDepartmentApiArg
      >({
        query: (queryArg) => ({
          url: `/masterCode/department/${queryArg.id}`,
          method: "PATCH",
          data: queryArg.patchRequest,
        }),
        invalidatesTags: ["updateDepartment"],
      }),
      deleteDepartment: build.mutation<
        DeleteDepartmentApiResponse,
        DeleteDepartmentApiArg
      >({
        query: (queryArg) => ({
          url: `/masterCode/department/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["deleteDepartment"],
      }),
      createDepartment: build.mutation<
        CreateDepartmentApiResponse,
        CreateDepartmentApiArg
      >({
        query: (queryArg) => ({
          url: `/masterCode/department`,
          method: "POST",
          data: queryArg.createDepartmentRequest,
        }),
        invalidatesTags: ["createDepartment"],
      }),
      getCategories: build.query<GetCategoriesApiResponse, GetCategoriesApiArg>(
        {
          query: (queryArg) => ({
            url: `/masterCode/categories`,
            params: {
              sortDirection: queryArg.sortDirection,
              keyword: queryArg.keyword,
              sortBy: queryArg.sortBy,
              status: queryArg.status,
              categoryType: queryArg.categoryType,
            },
          }),
          providesTags: ["getCategories"],
        },
      ),
      getCategory: build.query<GetCategoryApiResponse, GetCategoryApiArg>({
        query: (queryArg) => ({ url: `/masterCode/category/${queryArg.id}` }),
        providesTags: ["getCategory"],
      }),
      updateCategory: build.mutation<
        UpdateCategoryApiResponse,
        UpdateCategoryApiArg
      >({
        query: (queryArg) => ({
          url: `/masterCode/category/${queryArg.id}`,
          method: "PATCH",
          data: queryArg.patchRequest,
        }),
        invalidatesTags: ["updateCategory"],
      }),
      deleteCategory: build.mutation<
        DeleteCategoryApiResponse,
        DeleteCategoryApiArg
      >({
        query: (queryArg) => ({
          url: `/masterCode/category/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["deleteCategory"],
      }),
      createCategory: build.mutation<
        CreateCategoryApiResponse,
        CreateCategoryApiArg
      >({
        query: (queryArg) => ({
          url: `/masterCode/category`,
          method: "POST",
          data: queryArg.createCategoryRequest,
        }),
        invalidatesTags: ["createCategory"],
      }),
      getTaxes: build.query<GetTaxesApiResponse, GetTaxesApiArg>({
        query: (queryArg) => ({
          url: `/masterCode/taxes`,
          params: {
            sortDirection: queryArg.sortDirection,
            keyword: queryArg.keyword,
            sortBy: queryArg.sortBy,
            status: queryArg.status,
          },
        }),
        providesTags: ["getTaxes"],
      }),
      getTax: build.query<GetTaxApiResponse, GetTaxApiArg>({
        query: (queryArg) => ({ url: `/masterCode/tax/${queryArg.id}` }),
        providesTags: ["getTax"],
      }),
      updateTax: build.mutation<UpdateTaxApiResponse, UpdateTaxApiArg>({
        query: (queryArg) => ({
          url: `/masterCode/tax/${queryArg.id}`,
          method: "PATCH",
          data: queryArg.patchRequest,
        }),
        invalidatesTags: ["updateTax"],
      }),
      deleteTax: build.mutation<DeleteTaxApiResponse, DeleteTaxApiArg>({
        query: (queryArg) => ({
          url: `/masterCode/tax/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["deleteTax"],
      }),
      createTax: build.mutation<CreateTaxApiResponse, CreateTaxApiArg>({
        query: (queryArg) => ({
          url: `/masterCode/tax`,
          method: "POST",
          data: queryArg.createTaxRequest,
        }),
        invalidatesTags: ["createTax"],
      }),
      getRooms: build.query<GetRoomsApiResponse, GetRoomsApiArg>({
        query: (queryArg) => ({
          url: `/masterCode/rooms`,
          params: {
            sortDirection: queryArg.sortDirection,
            keyword: queryArg.keyword,
            status: queryArg.status,
            sortBy: queryArg.sortBy,
          },
        }),
        providesTags: ["getRooms"],
      }),
      getRoom: build.query<GetRoomApiResponse, GetRoomApiArg>({
        query: (queryArg) => ({ url: `/masterCode/room/${queryArg.id}` }),
        providesTags: ["getRoom"],
      }),
      updateRoom: build.mutation<UpdateRoomApiResponse, UpdateRoomApiArg>({
        query: (queryArg) => ({
          url: `/masterCode/room/${queryArg.id}`,
          method: "PATCH",
          data: queryArg.patchRequest,
        }),
        invalidatesTags: ["updateRoom"],
      }),
      deleteRoom: build.mutation<DeleteRoomApiResponse, DeleteRoomApiArg>({
        query: (queryArg) => ({
          url: `/masterCode/room/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["deleteRoom"],
      }),
      createRoom: build.mutation<CreateRoomApiResponse, CreateRoomApiArg>({
        query: (queryArg) => ({
          url: `/masterCode/room`,
          method: "POST",
          data: queryArg.createRoomRequest,
        }),
        invalidatesTags: ["createRoom"],
      }),
      updateRoomServices: build.mutation<
        UpdateRoomServicesApiResponse,
        UpdateRoomServicesApiArg
      >({
        query: (queryArg) => ({
          url: `/masterCode/room/services/${queryArg.id}`,
          method: "PUT",
          data: queryArg.roomServicePutRequest,
        }),
        invalidatesTags: ["updateRoomServices"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as edgeMasterApi };
export type GetDepartmentsApiResponse = /** status 200  */ DepartmentsResponse;
export type GetDepartmentsApiArg = {
  /** asc or desc */
  sortDirection?: string;
  keyword?: string;
  sortBy?: string;
  status?: number;
};
export type GetDepartmentApiResponse = /** status 200  */ DepartmentResponse;
export type GetDepartmentApiArg = {
  id: string;
};
export type UpdateDepartmentApiResponse = /** status 200  */ DepartmentResponse;
export type UpdateDepartmentApiArg = {
  id: string;
  patchRequest: PatchRequest;
};
export type DeleteDepartmentApiResponse = /** status 200  */ VoidResponse;
export type DeleteDepartmentApiArg = {
  id: string;
};
export type CreateDepartmentApiResponse = /** status 200  */ DepartmentResponse;
export type CreateDepartmentApiArg = {
  createDepartmentRequest: CreateDepartmentRequest;
};
export type GetCategoriesApiResponse = /** status 200  */ CategoriesResponse;
export type GetCategoriesApiArg = {
  /** asc or desc */
  sortDirection?: string;
  keyword?: string;
  sortBy?: string;
  status?: number;
  categoryType?: number;
};
export type GetCategoryApiResponse = /** status 200  */ CategoryResponse;
export type GetCategoryApiArg = {
  id: string;
};
export type UpdateCategoryApiResponse =
  /** status 200  */ UpdateCategoryResponse;
export type UpdateCategoryApiArg = {
  id: string;
  patchRequest: PatchRequest;
};
export type DeleteCategoryApiResponse = /** status 200  */ VoidResponse;
export type DeleteCategoryApiArg = {
  id: string;
};
export type CreateCategoryApiResponse =
  /** status 200  */ CreateCategoryResponse;
export type CreateCategoryApiArg = {
  createCategoryRequest: CreateCategoryRequest;
};
export type GetTaxesApiResponse = /** status 200  */ TaxesResponse;
export type GetTaxesApiArg = {
  /** asc or desc */
  sortDirection?: string;
  keyword?: string;
  sortBy?: string;
  status?: number;
};
export type GetTaxApiResponse = /** status 200  */ TaxResponse;
export type GetTaxApiArg = {
  id: string;
};
export type UpdateTaxApiResponse = /** status 200  */ TaxResponse;
export type UpdateTaxApiArg = {
  id: string;
  patchRequest: PatchRequest;
};
export type DeleteTaxApiResponse = /** status 200  */ VoidResponse;
export type DeleteTaxApiArg = {
  id: string;
};
export type CreateTaxApiResponse = /** status 200  */ TaxResponse;
export type CreateTaxApiArg = {
  createTaxRequest: CreateTaxRequest;
};
export type GetRoomsApiResponse = /** status 200  */ RoomsResponse;
export type GetRoomsApiArg = {
  /** asc or desc */
  sortDirection?: string;
  keyword?: string;
  status?: number;
  sortBy?: string;
};
export type GetRoomApiResponse = /** status 200  */ RoomResponse;
export type GetRoomApiArg = {
  id: string;
};
export type UpdateRoomApiResponse = /** status 200  */ RoomResponse;
export type UpdateRoomApiArg = {
  id: string;
  patchRequest: PatchRequest;
};
export type DeleteRoomApiResponse = /** status 200  */ VoidResponse;
export type DeleteRoomApiArg = {
  id: string;
};
export type CreateRoomApiResponse = /** status 200  */ RoomResponse;
export type CreateRoomApiArg = {
  createRoomRequest: CreateRoomRequest;
};
export type UpdateRoomServicesApiResponse =
  /** status 200  */ ServicesByRoomResponse;
export type UpdateRoomServicesApiArg = {
  id: string;
  roomServicePutRequest: RoomServicePutRequest;
};
export type DepartmentDto = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  name: string;
  description?: string;
  status: number;
};
export type DepartmentsResponse = {
  code?: number;
  messages?: string[];
  result?: DepartmentDto[];
};
export type DepartmentResponse = {
  code?: number;
  messages?: string[];
  result?: DepartmentDto;
};
export type PatchOperation =
  | "add"
  | "replace"
  | "test"
  | "remove"
  | "move"
  | "copy";
export type Patch = {
  op: PatchOperation;
  /** Path */
  path: string;
  /** Value */
  value?: object;
  /** From */
  from?: string;
};
export type PatchRequest = {
  jsonPatch: Patch[];
};
export type VoidResponse = {
  code?: number;
  messages?: string[];
};
export type CreateDepartmentRequest = {
  name: string;
  description?: string;
};
export type CategoryMeta = {
  taxIds?: string[];
  commission?: boolean;
};
export type TaxDto = {
  id: string;
  name: string;
  taxCode: string;
  description?: string;
  rate: number;
  type?: number;
  status: number;
};
export type RecursiveCategoryDto = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  name: string;
  path: string;
  level: number;
  type?: number;
  description?: string;
  parentId?: string;
  status: number;
  tags?: string;
  meta?: CategoryMeta;
  Tax?: TaxDto[];
};
export type CategoriesResponse = {
  code?: number;
  messages?: string[];
  result?: RecursiveCategoryDto[];
};
export type CategoryResponse = {
  code?: number;
  messages?: string[];
  result?: RecursiveCategoryDto;
};
export type CategoryDto = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  name: string;
  type?: number;
  description?: string;
  meta?: CategoryMeta;
  parentId?: string;
  status: number;
  tags?: string;
  Tax?: TaxDto[];
};
export type UpdateCategoryResponse = {
  code?: number;
  messages?: string[];
  result?: CategoryDto;
};
export type CreateCategoryResponse = {
  code?: number;
  messages?: string[];
  result?: CategoryDto;
};
export type CreateCategoryRequest = {
  name: string;
  type?: number;
  description?: string;
  meta?: CategoryMeta;
  parentId?: string;
  /** "espa","kbs" or "kbs" but use backslash in front of double quotation */
  tags?: string;
};
export type TaxesResponse = {
  code?: number;
  messages?: string[];
  result?: TaxDto[];
};
export type TaxResponse = {
  code?: number;
  messages?: string[];
  result?: TaxDto;
};
export type CreateTaxRequest = {
  name: string;
  taxCode: string;
  description?: string;
  rate: number;
  type?: number;
};
export type RoomDto = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  name: string;
  description?: string;
  capacity: number;
  status: number;
};
export type RoomsResponse = {
  code?: number;
  messages?: string[];
  result?: RoomDto[];
};
export type CategoryName = {
  name: string;
};
export type RoomProductDto = {
  id: string;
  categoryId?: string;
  name: string;
  status: number;
  duration?: number;
  Category?: CategoryName;
};
export type SingleRoomDto = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  name: string;
  description?: string;
  capacity: number;
  status: number;
  ProductServices?: RoomProductDto[];
};
export type RoomResponse = {
  code?: number;
  messages?: string[];
  result?: SingleRoomDto;
};
export type CreateRoomRequest = {
  name: string;
  description?: string;
  capacity: number;
  /** ["PD123456789012","PD123456789012"] */
  productIds?: string[];
};
export type ServicesByRoomResponse = {
  code?: number;
  messages?: string[];
  result?: RoomProductDto[];
};
export type RoomServicePutRequest = {
  /** ["PD123456789012","PD123456789012"] */
  productIds: string[];
};
export const {
  useGetDepartmentsQuery,
  useGetDepartmentQuery,
  useUpdateDepartmentMutation,
  useDeleteDepartmentMutation,
  useCreateDepartmentMutation,
  useGetCategoriesQuery,
  useGetCategoryQuery,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation,
  useCreateCategoryMutation,
  useGetTaxesQuery,
  useGetTaxQuery,
  useUpdateTaxMutation,
  useDeleteTaxMutation,
  useCreateTaxMutation,
  useGetRoomsQuery,
  useGetRoomQuery,
  useUpdateRoomMutation,
  useDeleteRoomMutation,
  useCreateRoomMutation,
  useUpdateRoomServicesMutation,
} = injectedRtkApi;

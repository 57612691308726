import { emptySplitApi as api } from "./emptyApi";
export const addTagTypes = [
  "postBookerCart",
  "deleteAllBookerCart",
  "getBookerCart",
  "deleteBookerCart",
  "getBookerAvailableDates",
  "getBookerAvailableTimes",
  "postSelectTime",
  "patchAgreement",
  "createBookerAppointment",
  "postBookerLogin",
  "postBookerLogout",
  "postBookerForgotPassword",
  "postBookerResetPassword",
  "createBookerAccount",
  "getBookerCustomer",
  "getBookerAppointmentsByCustomer",
  "getBookerAppointments",
  "getBookerEmployees",
  "getBookerCategories",
  "getBookerTreatments",
  "getBookerTreatment",
  "postEncryption",
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      postBookerCart: build.mutation<
        PostBookerCartApiResponse,
        PostBookerCartApiArg
      >({
        query: (queryArg) => ({
          url: `/booker/cart/${queryArg.treatmentId}`,
          method: "POST",
          data: queryArg.cartTreatment,
        }),
        invalidatesTags: ["postBookerCart"],
      }),
      deleteAllBookerCart: build.mutation<
        DeleteAllBookerCartApiResponse,
        DeleteAllBookerCartApiArg
      >({
        query: () => ({ url: `/booker/cart`, method: "DELETE" }),
        invalidatesTags: ["deleteAllBookerCart"],
      }),
      getBookerCart: build.query<GetBookerCartApiResponse, GetBookerCartApiArg>(
        {
          query: () => ({ url: `/booker/cart` }),
          providesTags: ["getBookerCart"],
        },
      ),
      deleteBookerCart: build.mutation<
        DeleteBookerCartApiResponse,
        DeleteBookerCartApiArg
      >({
        query: (queryArg) => ({
          url: `/booker/cart/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["deleteBookerCart"],
      }),
      getBookerAvailableDates: build.query<
        GetBookerAvailableDatesApiResponse,
        GetBookerAvailableDatesApiArg
      >({
        query: (queryArg) => ({
          url: `/booker/availableDates`,
          params: { fromDate: queryArg.fromDate, toDate: queryArg.toDate },
        }),
        providesTags: ["getBookerAvailableDates"],
      }),
      getBookerAvailableTimes: build.query<
        GetBookerAvailableTimesApiResponse,
        GetBookerAvailableTimesApiArg
      >({
        query: (queryArg) => ({
          url: `/booker/availableTimes`,
          params: { fromDate: queryArg.fromDate },
        }),
        providesTags: ["getBookerAvailableTimes"],
      }),
      postSelectTime: build.mutation<
        PostSelectTimeApiResponse,
        PostSelectTimeApiArg
      >({
        query: (queryArg) => ({
          url: `/booker/selectTime/${queryArg.dateTime}`,
          method: "POST",
        }),
        invalidatesTags: ["postSelectTime"],
      }),
      patchAgreement: build.mutation<
        PatchAgreementApiResponse,
        PatchAgreementApiArg
      >({
        query: () => ({ url: `/booker/agreement`, method: "PATCH" }),
        invalidatesTags: ["patchAgreement"],
      }),
      createBookerAppointment: build.mutation<
        CreateBookerAppointmentApiResponse,
        CreateBookerAppointmentApiArg
      >({
        query: (queryArg) => ({
          url: `/booker/createAppointment`,
          method: "POST",
          data: queryArg.createAppointmentRequest,
        }),
        invalidatesTags: ["createBookerAppointment"],
      }),
      postBookerLogin: build.mutation<
        PostBookerLoginApiResponse,
        PostBookerLoginApiArg
      >({
        query: (queryArg) => ({
          url: `/booker/login`,
          method: "POST",
          data: queryArg.loginRequest,
        }),
        invalidatesTags: ["postBookerLogin"],
      }),
      postBookerLogout: build.mutation<
        PostBookerLogoutApiResponse,
        PostBookerLogoutApiArg
      >({
        query: () => ({ url: `/booker/logout`, method: "POST" }),
        invalidatesTags: ["postBookerLogout"],
      }),
      postBookerForgotPassword: build.mutation<
        PostBookerForgotPasswordApiResponse,
        PostBookerForgotPasswordApiArg
      >({
        query: (queryArg) => ({
          url: `/booker/forgotPassword`,
          method: "POST",
          data: queryArg.forgotPasswordRequest,
        }),
        invalidatesTags: ["postBookerForgotPassword"],
      }),
      postBookerResetPassword: build.mutation<
        PostBookerResetPasswordApiResponse,
        PostBookerResetPasswordApiArg
      >({
        query: (queryArg) => ({
          url: `/booker/resetPassword/${queryArg.key}`,
          method: "POST",
          data: queryArg.resetPasswordRequest,
        }),
        invalidatesTags: ["postBookerResetPassword"],
      }),
      createBookerAccount: build.mutation<
        CreateBookerAccountApiResponse,
        CreateBookerAccountApiArg
      >({
        query: (queryArg) => ({
          url: `/booker/createAccount`,
          method: "POST",
          data: queryArg.createAccountRequest,
        }),
        invalidatesTags: ["createBookerAccount"],
      }),
      getBookerCustomer: build.query<
        GetBookerCustomerApiResponse,
        GetBookerCustomerApiArg
      >({
        query: () => ({ url: `/booker/customer` }),
        providesTags: ["getBookerCustomer"],
      }),
      getBookerAppointmentsByCustomer: build.query<
        GetBookerAppointmentsByCustomerApiResponse,
        GetBookerAppointmentsByCustomerApiArg
      >({
        query: () => ({ url: `/booker/appointmentsByCustomer` }),
        providesTags: ["getBookerAppointmentsByCustomer"],
      }),
      getBookerAppointments: build.query<
        GetBookerAppointmentsApiResponse,
        GetBookerAppointmentsApiArg
      >({
        query: (queryArg) => ({
          url: `/booker/appointments`,
          params: { date: queryArg.date },
        }),
        providesTags: ["getBookerAppointments"],
      }),
      getBookerEmployees: build.query<
        GetBookerEmployeesApiResponse,
        GetBookerEmployeesApiArg
      >({
        query: () => ({ url: `/booker/employees` }),
        providesTags: ["getBookerEmployees"],
      }),
      getBookerCategories: build.query<
        GetBookerCategoriesApiResponse,
        GetBookerCategoriesApiArg
      >({
        query: () => ({ url: `/booker/categories` }),
        providesTags: ["getBookerCategories"],
      }),
      getBookerTreatments: build.query<
        GetBookerTreatmentsApiResponse,
        GetBookerTreatmentsApiArg
      >({
        query: (queryArg) => ({
          url: `/booker/treatments/${queryArg.categoryId}`,
        }),
        providesTags: ["getBookerTreatments"],
      }),
      getBookerTreatment: build.query<
        GetBookerTreatmentApiResponse,
        GetBookerTreatmentApiArg
      >({
        query: (queryArg) => ({ url: `/booker/treatment/${queryArg.id}` }),
        providesTags: ["getBookerTreatment"],
      }),
      postEncryption: build.mutation<
        PostEncryptionApiResponse,
        PostEncryptionApiArg
      >({
        query: () => ({ url: `/booker/encryption`, method: "POST" }),
        invalidatesTags: ["postEncryption"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as edgeBookerApi };
export type PostBookerCartApiResponse = /** status 200  */ BookerCart;
export type PostBookerCartApiArg = {
  /** Booker Treatment Id */
  treatmentId: string;
  cartTreatment: CartTreatment;
};
export type DeleteAllBookerCartApiResponse = /** status 200  */ VoidResponse;
export type DeleteAllBookerCartApiArg = void;
export type GetBookerCartApiResponse = /** status 200  */ BookerCart;
export type GetBookerCartApiArg = void;
export type DeleteBookerCartApiResponse = /** status 200  */ VoidResponse;
export type DeleteBookerCartApiArg = {
  /** CartItem Id */
  id: string;
};
export type GetBookerAvailableDatesApiResponse = /** status 200  */ string[];
export type GetBookerAvailableDatesApiArg = {
  fromDate: string;
  toDate: string;
};
export type GetBookerAvailableTimesApiResponse = /** status 200  */ string[];
export type GetBookerAvailableTimesApiArg = {
  fromDate: string;
};
export type PostSelectTimeApiResponse = /** status 200  */ BookerCart;
export type PostSelectTimeApiArg = {
  dateTime: string;
};
export type PatchAgreementApiResponse = /** status 200  */ BookerCart;
export type PatchAgreementApiArg = void;
export type CreateBookerAppointmentApiResponse =
  /** status 200  */ CreateAppointmentResponse;
export type CreateBookerAppointmentApiArg = {
  createAppointmentRequest: CreateAppointmentRequest;
};
export type PostBookerLoginApiResponse = /** status 200  */ LoginResponse;
export type PostBookerLoginApiArg = {
  loginRequest: LoginRequest;
};
export type PostBookerLogoutApiResponse = /** status 200  */ VoidResponse;
export type PostBookerLogoutApiArg = void;
export type PostBookerForgotPasswordApiResponse =
  /** status 200  */ VoidResponse;
export type PostBookerForgotPasswordApiArg = {
  forgotPasswordRequest: ForgotPasswordRequest;
};
export type PostBookerResetPasswordApiResponse =
  /** status 200  */ VoidResponse;
export type PostBookerResetPasswordApiArg = {
  key: string;
  resetPasswordRequest: ResetPasswordRequest;
};
export type CreateBookerAccountApiResponse =
  /** status 200  */ CreateAccountResponse;
export type CreateBookerAccountApiArg = {
  createAccountRequest: CreateAccountRequest;
};
export type GetBookerCustomerApiResponse = /** status 200  */ CustomerResponse;
export type GetBookerCustomerApiArg = void;
export type GetBookerAppointmentsByCustomerApiResponse =
  /** status 200  */ TreatmentResponse;
export type GetBookerAppointmentsByCustomerApiArg = void;
export type GetBookerAppointmentsApiResponse =
  /** status 200  */ AppointmentsResponse;
export type GetBookerAppointmentsApiArg = {
  date: string;
};
export type GetBookerEmployeesApiResponse = /** status 200  */ EmployeeResponse;
export type GetBookerEmployeesApiArg = void;
export type GetBookerCategoriesApiResponse =
  /** status 200  */ CategoriesResponse;
export type GetBookerCategoriesApiArg = void;
export type GetBookerTreatmentsApiResponse =
  /** status 200  */ TreatmentsResponse;
export type GetBookerTreatmentsApiArg = {
  categoryId: string;
};
export type GetBookerTreatmentApiResponse =
  /** status 200  */ TreatmentResponse;
export type GetBookerTreatmentApiArg = {
  id: string;
};
export type PostEncryptionApiResponse = unknown;
export type PostEncryptionApiArg = void;
export type Guest = {
  preferenceGender?: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
};
export type BookerItemMap = {
  treatmentId?: string;
  myPreferenceGender?: string;
  guest1?: Guest;
  guest2?: Guest;
  name: string;
  price: number;
  imageUrl: string;
  isForCouples: boolean;
  treatmentDuration: number;
  startDateTime: string;
  employee1Id: number;
  employee2Id: number;
  categoryName: string;
  subCategoryName: string;
};
export type BookerItem = {
  id: string;
  productId?: string;
  orderId?: string;
  orderNumber?: string;
  quantity?: number;
  meta?: BookerItemMap;
};
export type BookerCart = {
  id: string;
  items: BookerItem[];
  isAllowGuest: boolean;
  isAllowMySelf: boolean;
  isReadyToBook: boolean;
  isAgreed: boolean;
  totalAmount: number;
  customerId: string;
  customerName: string;
};
export type CartTreatment = {
  treatmentId?: string;
  myPreferenceGender?: string;
  guest1?: Guest;
  guest2?: Guest;
};
export type VoidResponse = {
  code?: number;
  messages?: string[];
};
export type ArgumentError = {
  ArgumentName: string;
  ErrorMessage: string;
};
export type OrderInformation = {
  id: string;
  items: BookerItem[];
  isAllowGuest: boolean;
  isAllowMySelf: boolean;
  isReadyToBook: boolean;
  isAgreed: boolean;
  totalAmount: number;
  customerId: string;
  customerName: string;
  BillingZip: string;
  ExpirationDate: string;
  NameOnCard: string;
  Number: string;
  CardType: number;
};
export type CreateAppointmentResponse = {
  ArgumentErrors: ArgumentError[];
  ErrorCode: number;
  ErrorMessage: string;
  IsSuccess: boolean;
  OrderInformation: OrderInformation;
};
export type CreateAppointmentRequest = {
  /** { cardNumber: string, year: number(yyyy), month: number(MM), cvc: string } */
  CreditCard: string;
  CardHolderName: string;
  BillingZip: string;
  CardType: number;
};
export type LoginResponse = {
  access_token: string;
  error: string;
  error_description: string;
  expires_in: string;
};
export type LoginRequest = {
  Email: string;
  Password: string;
};
export type BookerResponse = {
  ArgumentErrors: ArgumentError[];
  ErrorCode: number;
  ErrorMessage: string;
  IsSuccess: boolean;
};
export type BookerErrorResponse = {
  message: BookerResponse;
};
export type ForgotPasswordRequest = {
  Email: string;
  Firstname: string;
  BaseUrlOfHost: string;
};
export type ResetPasswordRequest = {
  Password: string;
};
export type CreateAccountResponse = {
  ArgumentErrors: ArgumentError[];
  ErrorCode: number;
  ErrorMessage: string;
  IsSuccess: boolean;
  CustomerID: number;
};
export type CreateAccountRequest = {
  FirstName: string;
  LastName: string;
  HomePhone: string;
  Email: string;
  Password: string;
};
export type CustomerResponse = {};
export type AvailableInAdvanceDateUnitType = {
  ID: number;
  Name: string;
};
export type Price = {
  Amount: number;
  CurrencyCode: string;
};
export type Treatment = {
  AllowCustomersToBookOnline: boolean;
  BillableItemID: number;
  Category: AvailableInAdvanceDateUnitType;
  DepositOptions: object;
  Description: string;
  DisplayName: string;
  ID: number;
  IsActive: boolean;
  IsDeleted: boolean;
  Name: string;
  Price: Price;
  SubCategory: AvailableInAdvanceDateUnitType;
  TotalDuration: number;
  TreatmentDuration: number;
  ImageURL: string;
  DurationType: AvailableInAdvanceDateUnitType;
  FlexiblePriceIncrementType: AvailableInAdvanceDateUnitType;
  IsBoundingService: boolean;
  IsFlexiblePrice: boolean;
  IsSharedService: boolean;
  MaxTreatmentDuration: string;
  MinTreatmentDuration: string;
  SharedRoomGuestCount: string;
  ColorCode: string;
  DoesNotRequireStaff: boolean;
  IsForCouples: boolean;
  AvailableInAdvance: number;
  DateCreated: string;
  DateLastModified: string;
  AvailableInAdvanceDateUnitType: AvailableInAdvanceDateUnitType;
  CustomerRecordType: AvailableInAdvanceDateUnitType;
  IsClass: boolean;
  CustomerTypeID: number;
  CustomerTypeName: string;
  RequiresTwoTechnicians: boolean;
  DateCreatedOffset: string;
  DateLastModifiedOffset: string;
  OnlineMenuType: AvailableInAdvanceDateUnitType;
  IsVerifiedVisibleInOnlineBooking: boolean;
  EmployeeTreatments: string;
};
export type TreatmentResponse = {
  ArgumentErrors: ArgumentError[];
  ErrorCode: number;
  ErrorMessage: string;
  IsSuccess: boolean;
  Treatment: Treatment;
};
export type FinalTotal = {};
export type Status = {};
export type AppointmentPayment = {};
export type Gender = {};
export type Employee = {
  DisplayName: string;
  FirstName: string;
  Gender: Gender;
  ID: number;
  LastName: string;
  LocationID: number;
  Photo: string;
  ProfileDescription: string;
  Rank: number;
  Type: number;
};
export type AppointmentTreatment = {
  AppointmentID: number;
  EndDateTime: string;
  ID: number;
  StartDateTime: string;
  FullStayGroupID: number;
  SharedRoomGroupID: number;
  ChildID: number;
  ChildName: string;
  StartDateTimeOffset: string;
  EndDateTimeOffset: string;
  EmployeeWasRequested: boolean;
  PreferenceGenderFemale: boolean;
  Guest: Guest;
  Treatment: Treatment;
  TreatmentDuration: number;
  Employee: Employee;
  Employee2: Employee;
};
export type Appointment = {
  BookingNumber: string;
  Confirmable: boolean;
  EndDateTime: string;
  FinalTotal: FinalTotal;
  ID: number;
  IsCancelled: boolean;
  IsNoShow: boolean;
  LocationID: number;
  StartDateTime: string;
  Status: Status;
  CanTakePayment: boolean;
  DateCreated: string;
  BelongsToEnrollment: boolean;
  OrderID: number;
  StartDateTimeOffset: string;
  EndDateTimeOffset: string;
  DateCreatedOffset: string;
  AppointmentPayment: AppointmentPayment;
  AppointmentTreatments: AppointmentTreatment[];
  Customer: object;
  InvoiceID: number;
  Treatment: Treatment;
  GroupAppointmentID: number;
  BookedDateTime: string;
  AddOnItems: string[];
  Child: object;
  BookedDateTimeOffset: string;
};
export type AppointmentsResponse = {
  ArgumentErrors: ArgumentError[];
  ErrorCode: number;
  ErrorMessage: string;
  IsSuccess: boolean;
  Results: Appointment[];
  TotalResultsCount: number;
};
export type EmployeeResponse = {
  ArgumentErrors: ArgumentError[];
  ErrorCode: number;
  ErrorMessage: string;
  IsSuccess: boolean;
  Results: Employee[];
};
export type LookupOption = {
  id: string;
  name: string;
};
export type CategoriesResponse = {
  ArgumentErrors: ArgumentError[];
  ErrorCode: number;
  ErrorMessage: string;
  IsSuccess: boolean;
  LookupOptions: LookupOption[];
};
export type TreatmentsResponse = {
  ArgumentErrors: ArgumentError[];
  ErrorCode: number;
  ErrorMessage: string;
  IsSuccess: boolean;
  Treatments: Treatment[];
  TotalResultsCount: number;
};
export const {
  usePostBookerCartMutation,
  useDeleteAllBookerCartMutation,
  useGetBookerCartQuery,
  useDeleteBookerCartMutation,
  useGetBookerAvailableDatesQuery,
  useGetBookerAvailableTimesQuery,
  usePostSelectTimeMutation,
  usePatchAgreementMutation,
  useCreateBookerAppointmentMutation,
  usePostBookerLoginMutation,
  usePostBookerLogoutMutation,
  usePostBookerForgotPasswordMutation,
  usePostBookerResetPasswordMutation,
  useCreateBookerAccountMutation,
  useGetBookerCustomerQuery,
  useGetBookerAppointmentsByCustomerQuery,
  useGetBookerAppointmentsQuery,
  useGetBookerEmployeesQuery,
  useGetBookerCategoriesQuery,
  useGetBookerTreatmentsQuery,
  useGetBookerTreatmentQuery,
  usePostEncryptionMutation,
} = injectedRtkApi;

import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { styled } from '@mui/system';
import { Icon } from '@iconify/react';
import { Typography, TypographyOwnProps } from '@mui/material';

type Variant = 'warning' | 'success' | 'info' | 'error' | 'confirm' | 'warning_with_reverse_order_button' | undefined;
export interface ConfirmationOptions {
  catchOnCancel?: boolean;
  variant?: Variant;
  title?: string | undefined;
  description?: string | string[] | undefined;
  hasIcon?: boolean;
  closeButtonText?: string | undefined;
  submitButtonText?: string | undefined;
}

type FixedIconifyIconProps = { iconName: string; color: string };
const FixedIconifyIcon = (props: FixedIconifyIconProps) => {
  return <Icon icon={props.iconName} style={{ width: 80, height: 80, color: props.color, alignSelf: 'center' }} />;
};
export interface ConfirmationDialogProps extends ConfirmationOptions {
  open?: boolean;
  onSubmit?: () => void;
  onClose?: () => void;
}

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({ open, title, variant, description, hasIcon, closeButtonText, submitButtonText, onSubmit, onClose }) => {
  if (!open) return <></>;

  const singleDescriptionStyle: TypographyOwnProps = hasIcon ? { variant: 'h5', color: 'text.primary', textAlign: 'center' } : { variant: 'body1', color: 'text.secondary' };
  return (
    <Dialog open={open} aria-labelledby="alert-dialog-title2" PaperProps={{ style: { display: 'flex', gap: '0px', width: '560px', padding: '15px' } }}>
      {(hasIcon || title) && (
        <DialogTitle id="alert-dialog-title2" component={'div'} sx={{ display: 'flex', flexDirection: 'column', justifyContent: hasIcon ? 'center' : 'flex-start' }}>
          {hasIcon && <FixedIconifyIcon {...getIconProperty(variant)} />}
          {title && (
            <Typography variant="h5" color={'text.primary'} textAlign={hasIcon ? 'center' : null}>
              {title}
            </Typography>
          )}
        </DialogTitle>
      )}
      <DialogContent sx={{ alignSelf: 'stretch' }}>
        {Array.isArray(description) && (
          <ul>
            {description.map((desc, index) => (
              <li key={`alert-dialog-${index}`}>
                <Typography variant="body1" color={'text.secondary'}>
                  {desc}
                </Typography>
              </li>
            ))}
          </ul>
        )}
        {!Array.isArray(description) && <Typography {...singleDescriptionStyle}>{description}</Typography>}
      </DialogContent>
      <DialogActions className="dialog-actions-dense" style={{ display: 'flex', justifyContent: hasIcon ? 'center' : 'flex-end', gap: '2px' }}>
        {!variant && closeButtonText && submitButtonText && (
          <>
            <Button color="primary" variant="outlined" onClick={onClose}>
              {closeButtonText}
            </Button>
            <Button color="primary" variant="contained" onClick={onSubmit} autoFocus>
              {submitButtonText}
            </Button>
          </>
        )}
        {variant === 'warning' && (
          <>
            <Button color="primary" variant="contained" onClick={onSubmit}>
              YES
            </Button>
            <Button color="primary" variant="outlined" onClick={onClose} autoFocus>
              NO
            </Button>
          </>
        )}
        {variant === 'warning_with_reverse_order_button' && (
          <>
            <Button color="primary" variant="outlined" onClick={onClose} autoFocus>
              NO
            </Button>
            <Button color="primary" variant="contained" onClick={onSubmit}>
              YES
            </Button>
          </>
        )}
        {variant === 'success' && (
          <Button size="large" color="primary" variant="contained" onClick={onSubmit} autoFocus>
            OK
          </Button>
        )}
        {variant === 'info' && (
          <Button color="primary" variant="contained" onClick={onSubmit} autoFocus>
            OK
          </Button>
        )}

        {variant === 'error' && (
          <Button color="primary" variant="contained" onClick={onClose} autoFocus>
            OK
          </Button>
        )}
        {variant === 'confirm' && (
          <>
            <Button color="primary" variant="contained" onClick={onSubmit} autoFocus>
              CONFIRM
            </Button>
            <Button color="primary" variant="outlined" onClick={onClose}>
              CANCEL
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

const getIconProperty = (variant: Variant) => {
  const iconProperties: Partial<Record<Variant, { iconName: string; color: string }>> = {
    success: { iconName: 'mdi:check-circle', color: '#4BB543' },
    info: { iconName: 'mdi:alert-circle-outline', color: '#FDB528' },
    error: { iconName: 'mdi:alert-circle-outline', color: '#FF0000' },
    warning: { iconName: 'mdi:alert-circle-outline', color: '#FDB528' },
  };
  return iconProperties[variant];
};

import * as React from 'react';
import { ConfirmationDialog, ConfirmationDialogProps, ConfirmationOptions } from './AlertDialog';

const ConfirmationServiceContext = React.createContext<(options: ConfirmationOptions) => Promise<void>>(Promise.reject);
export const useConfirmation = () => React.useContext(ConfirmationServiceContext);

const commonConfirmationOptions: ConfirmationOptions = { title: '', variant: 'error', hasIcon: true, catchOnCancel: true };

export const ConfirmationServiceProvider = ({ children, confirmationDialog }: React.PropsWithChildren<{ confirmationDialog: React.ReactElement<ConfirmationDialogProps> }>) => {
  const [confirmationState, setConfirmationState] = React.useState<ConfirmationOptions | null>(null);
  const awaitingPromiseRef = React.useRef<{
    resolve: () => void;
    reject: () => void;
  }>();

  const openConfirmation = (options: ConfirmationOptions) => {
    setConfirmationState(options);

    return new Promise<void>((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  const handleClose = () => {
    if (confirmationState?.catchOnCancel && awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject();
    }

    setConfirmationState(null);
  };

  const handleSubmit = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve();
    }
    setConfirmationState(null);
  };

  return (
    <>
      <ConfirmationServiceContext.Provider value={openConfirmation}>{children}</ConfirmationServiceContext.Provider>
      {React.cloneElement(confirmationDialog, { open: Boolean(confirmationState), onSubmit: handleSubmit, onClose: handleClose, ...confirmationState })}
      {/* <ConfirmationDialog key={'alertDialog'} open={Boolean(confirmationState)} onSubmit={handleSubmit} onClose={handleClose} {...confirmationState} /> */}
    </>
  );
};

type ApplicationErrorType = { code?: number; messages?: string[] };
export const getTitleAndDescriptionFromMessages = (message: string | string[], singleErrorTitle?: string) => {
  if (typeof message == 'string') {
    const messages = message?.split('|');
    if (messages?.length == 2) return { title: messages[0], description: messages[1] };
    return { title: singleErrorTitle, description: message };
  }

  if (message?.length > 0) {
    const messages = message[0]?.split('|');
    if (messages?.length >= 2) return { title: messages[0], description: messages?.splice(1).join(', ') };
    return { title: singleErrorTitle, description: messages?.join(', ') };
  }
};

const getErrorOptions = (message: string | string[], singleErrorTitle?: string): ConfirmationOptions => {
  return { ...commonConfirmationOptions, ...getTitleAndDescriptionFromMessages(message, singleErrorTitle) };
};
export const createErrorOptionsByApplicationError = (error: ApplicationErrorType, singleErrorTitle?: string): ConfirmationOptions => {
  if (!error) return null;
  return getErrorOptions(error.messages, singleErrorTitle);
};
export const createErrorToastOptionByApplicationError = (error: ApplicationErrorType): string => {
  if (!error) return null;
  const { title, description } = getTitleAndDescriptionFromMessages(error.messages);
  let wholeDescription = description;
  if (title) wholeDescription = title.concat(': ', description);
  return wholeDescription;
};

export const createErrorOptionsByServerStatusError = (error: Error, singleErrorTitle?: string): ConfirmationOptions => {
  if (!error) return null;
  return getErrorOptions(error.message, singleErrorTitle);
};

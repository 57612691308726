import { emptySplitApi as api } from "./emptyApi";
export const addTagTypes = [
  "getProductWithPriceByDate",
  "getAvailableAllTimeslots",
  "getScheduleForSettings",
  "patchSchedule",
  "deleteSchedule",
  "publish",
  "patchTimeslot",
  "calendarPriceByDay",
  "getAvailableTimeslots",
  "getFirstAvailableTimeslot",
  "getCartForReservationAdmission",
  "holdTimeslot",
  "getHeldTimeslot",
  "renewTimeslot",
  "releaseTimeslot",
  "patchReservationAdmission",
  "createIncompleteOrderForReservationAdmission",
  "updateIncompleteOrderForReservationAdmission",
  "confirmOrderForReservationAdmission",
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getProductWithPriceByDate: build.query<
        GetProductWithPriceByDateApiResponse,
        GetProductWithPriceByDateApiArg
      >({
        query: (queryArg) => ({
          url: `/reservation/admission/productWithPriceByDate`,
          params: { date: queryArg.date },
        }),
        providesTags: ["getProductWithPriceByDate"],
      }),
      getAvailableAllTimeslots: build.query<
        GetAvailableAllTimeslotsApiResponse,
        GetAvailableAllTimeslotsApiArg
      >({
        query: (queryArg) => ({
          url: `/reservation/admission/availableAllTimeslots`,
          params: { from: queryArg["from"], to: queryArg.to },
        }),
        providesTags: ["getAvailableAllTimeslots"],
      }),
      getScheduleForSettings: build.query<
        GetScheduleForSettingsApiResponse,
        GetScheduleForSettingsApiArg
      >({
        query: (queryArg) => ({
          url: `/reservation/admission/scheduleForSettings`,
          params: { dateFrom: queryArg.dateFrom, dateTo: queryArg.dateTo },
        }),
        providesTags: ["getScheduleForSettings"],
      }),
      patchSchedule: build.mutation<
        PatchScheduleApiResponse,
        PatchScheduleApiArg
      >({
        query: (queryArg) => ({
          url: `/reservation/admission/schedules`,
          method: "PATCH",
          data: queryArg.patchScheduleRequest,
        }),
        invalidatesTags: ["patchSchedule"],
      }),
      deleteSchedule: build.mutation<
        DeleteScheduleApiResponse,
        DeleteScheduleApiArg
      >({
        query: (queryArg) => ({
          url: `/reservation/admission/schedules`,
          method: "DELETE",
          params: { dateFrom: queryArg.dateFrom, dateTo: queryArg.dateTo },
        }),
        invalidatesTags: ["deleteSchedule"],
      }),
      publish: build.mutation<PublishApiResponse, PublishApiArg>({
        query: (queryArg) => ({
          url: `/reservation/admission/publish`,
          method: "POST",
          data: queryArg.publishRequest,
        }),
        invalidatesTags: ["publish"],
      }),
      patchTimeslot: build.mutation<
        PatchTimeslotApiResponse,
        PatchTimeslotApiArg
      >({
        query: (queryArg) => ({
          url: `/reservation/admission/timeslot/${queryArg.id}`,
          method: "PATCH",
          data: queryArg.patchRequest,
        }),
        invalidatesTags: ["patchTimeslot"],
      }),
      calendarPriceByDay: build.query<
        CalendarPriceByDayApiResponse,
        CalendarPriceByDayApiArg
      >({
        query: (queryArg) => ({
          url: `/reservation/admission/calendarPriceByDay`,
          params: { dateFrom: queryArg.dateFrom, dateTo: queryArg.dateTo },
        }),
        providesTags: ["calendarPriceByDay"],
      }),
      getAvailableTimeslots: build.query<
        GetAvailableTimeslotsApiResponse,
        GetAvailableTimeslotsApiArg
      >({
        query: (queryArg) => ({
          url: `/reservation/admission/availableTimeslots`,
          params: {
            dateFrom: queryArg.dateFrom,
            dateTo: queryArg.dateTo,
            numberOfGuest: queryArg.numberOfGuest,
          },
        }),
        providesTags: ["getAvailableTimeslots"],
      }),
      getFirstAvailableTimeslot: build.query<
        GetFirstAvailableTimeslotApiResponse,
        GetFirstAvailableTimeslotApiArg
      >({
        query: (queryArg) => ({
          url: `/reservation/admission/firstAvailableTimeslot/${queryArg.numberOfGuest}`,
        }),
        providesTags: ["getFirstAvailableTimeslot"],
      }),
      getCartForReservationAdmission: build.query<
        GetCartForReservationAdmissionApiResponse,
        GetCartForReservationAdmissionApiArg
      >({
        query: () => ({ url: `/reservation/admission/cart` }),
        providesTags: ["getCartForReservationAdmission"],
      }),
      holdTimeslot: build.mutation<HoldTimeslotApiResponse, HoldTimeslotApiArg>(
        {
          query: (queryArg) => ({
            url: `/reservation/admission/timeslots/${queryArg.id}/hold/${queryArg.numberOfGuest}`,
            method: "POST",
            params: { ttl: queryArg.ttl, override: queryArg["override"] },
          }),
          invalidatesTags: ["holdTimeslot"],
        },
      ),
      getHeldTimeslot: build.query<
        GetHeldTimeslotApiResponse,
        GetHeldTimeslotApiArg
      >({
        query: (queryArg) => ({
          url: `/reservation/admission/held/${queryArg.id}`,
        }),
        providesTags: ["getHeldTimeslot"],
      }),
      renewTimeslot: build.mutation<
        RenewTimeslotApiResponse,
        RenewTimeslotApiArg
      >({
        query: (queryArg) => ({
          url: `/reservation/admission/held/${queryArg.id}/renew`,
          method: "PATCH",
          params: { ttl: queryArg.ttl, override: queryArg["override"] },
        }),
        invalidatesTags: ["renewTimeslot"],
      }),
      releaseTimeslot: build.mutation<
        ReleaseTimeslotApiResponse,
        ReleaseTimeslotApiArg
      >({
        query: (queryArg) => ({
          url: `/reservation/admission/held/${queryArg.id}/release`,
          method: "DELETE",
        }),
        invalidatesTags: ["releaseTimeslot"],
      }),
      patchReservationAdmission: build.mutation<
        PatchReservationAdmissionApiResponse,
        PatchReservationAdmissionApiArg
      >({
        query: (queryArg) => ({
          url: `/reservation/admission/${queryArg.reservationId}`,
          method: "PATCH",
          data: queryArg.patchRequest,
        }),
        invalidatesTags: ["patchReservationAdmission"],
      }),
      createIncompleteOrderForReservationAdmission: build.mutation<
        CreateIncompleteOrderForReservationAdmissionApiResponse,
        CreateIncompleteOrderForReservationAdmissionApiArg
      >({
        query: (queryArg) => ({
          url: `/reservation/admission/incomplete`,
          method: "POST",
          data: queryArg.createIncompleteOrderForReservationAdmissionRequest,
        }),
        invalidatesTags: ["createIncompleteOrderForReservationAdmission"],
      }),
      updateIncompleteOrderForReservationAdmission: build.mutation<
        UpdateIncompleteOrderForReservationAdmissionApiResponse,
        UpdateIncompleteOrderForReservationAdmissionApiArg
      >({
        query: (queryArg) => ({
          url: `/reservation/admission/incomplete/${queryArg.id}`,
          method: "PUT",
          data: queryArg.updateIncompleteOrderRequest,
        }),
        invalidatesTags: ["updateIncompleteOrderForReservationAdmission"],
      }),
      confirmOrderForReservationAdmission: build.mutation<
        ConfirmOrderForReservationAdmissionApiResponse,
        ConfirmOrderForReservationAdmissionApiArg
      >({
        query: (queryArg) => ({
          url: `/reservation/admission/confirm/${queryArg.id}`,
          method: "POST",
          data: queryArg.confirmOrderRequest,
        }),
        invalidatesTags: ["confirmOrderForReservationAdmission"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as edgeReservationAdmissionApi };
export type GetProductWithPriceByDateApiResponse =
  /** status 200  */ GetProductWithPriceByDateResponse;
export type GetProductWithPriceByDateApiArg = {
  date: string;
};
export type GetAvailableAllTimeslotsApiResponse =
  /** status 200  */ GetAvailableAllTimeslotsResponse;
export type GetAvailableAllTimeslotsApiArg = {
  from: any;
  to: any;
};
export type GetScheduleForSettingsApiResponse =
  /** status 200  */ GetScheduleForSettingsResponse;
export type GetScheduleForSettingsApiArg = {
  dateFrom: string;
  dateTo: string;
};
export type PatchScheduleApiResponse = /** status 200  */ PatchScheduleResponse;
export type PatchScheduleApiArg = {
  patchScheduleRequest: PatchScheduleRequest;
};
export type DeleteScheduleApiResponse =
  /** status 200  */ DeleteScheduleResponse;
export type DeleteScheduleApiArg = {
  dateFrom: string;
  dateTo: string;
};
export type PublishApiResponse = /** status 200  */ PublishResponse;
export type PublishApiArg = {
  publishRequest: PublishRequest;
};
export type PatchTimeslotApiResponse =
  /** status 200  */ UpdateTimeslotStatusResponse;
export type PatchTimeslotApiArg = {
  id: string;
  patchRequest: PatchRequest;
};
export type CalendarPriceByDayApiResponse =
  /** status 200  */ CalendarPriceByDayResponse;
export type CalendarPriceByDayApiArg = {
  dateFrom: string;
  dateTo: string;
};
export type GetAvailableTimeslotsApiResponse =
  /** status 200  */ AvailableTimeslotsResponse;
export type GetAvailableTimeslotsApiArg = {
  dateFrom: string;
  dateTo?: string;
  numberOfGuest?: number;
};
export type GetFirstAvailableTimeslotApiResponse =
  /** status 200  */ FirstAvailableTimeslotResponse;
export type GetFirstAvailableTimeslotApiArg = {
  numberOfGuest: any;
};
export type GetCartForReservationAdmissionApiResponse =
  /** status 200  */ CartResponse;
export type GetCartForReservationAdmissionApiArg = void;
export type HoldTimeslotApiResponse = /** status 200  */ HoldTimeslotResponse;
export type HoldTimeslotApiArg = {
  ttl?: number;
  override?: boolean;
  id: string;
  numberOfGuest: any;
};
export type GetHeldTimeslotApiResponse =
  /** status 200  */ HeldTimeslotResponse;
export type GetHeldTimeslotApiArg = {
  id: string;
};
export type RenewTimeslotApiResponse = /** status 200  */ RenewTimeslotResponse;
export type RenewTimeslotApiArg = {
  ttl?: number;
  override?: boolean;
  id: string;
};
export type ReleaseTimeslotApiResponse =
  /** status 200  */ ReleaseTimeslotResponse;
export type ReleaseTimeslotApiArg = {
  id: any;
};
export type PatchReservationAdmissionApiResponse =
  /** status 200  */ UpdateTimeSlotResponse;
export type PatchReservationAdmissionApiArg = {
  reservationId: string;
  patchRequest: PatchRequest;
};
export type CreateIncompleteOrderForReservationAdmissionApiResponse =
  /** status 200  */ IncompleteOrderResponse;
export type CreateIncompleteOrderForReservationAdmissionApiArg = {
  createIncompleteOrderForReservationAdmissionRequest: CreateIncompleteOrderForReservationAdmissionRequest;
};
export type UpdateIncompleteOrderForReservationAdmissionApiResponse =
  /** status 200  */ IncompleteOrderResponse;
export type UpdateIncompleteOrderForReservationAdmissionApiArg = {
  id: string;
  updateIncompleteOrderRequest: UpdateIncompleteOrderRequest;
};
export type ConfirmOrderForReservationAdmissionApiResponse =
  /** status 200  */ ConfirmOrderResponse;
export type ConfirmOrderForReservationAdmissionApiArg = {
  id: string;
  confirmOrderRequest: ConfirmOrderRequest;
};
export type GetProductWithPriceByDateResponseResultProduct = {
  productId: string;
  name: string;
  price: number;
};
export type GetProductWithPriceByDateResponseResult = {
  datetime: string;
  year: number;
  month: number;
  date: number;
  weekday: number;
  isWeekend: boolean;
  isHoliday: boolean;
  isBankHoliday: boolean;
  holidayText: string;
  isPublished: boolean;
  dateslotId: string;
  products: GetProductWithPriceByDateResponseResultProduct[];
};
export type GetProductWithPriceByDateResponse = {
  code?: number;
  messages?: string[];
  result?: GetProductWithPriceByDateResponseResult[];
};
export type GetAvailableAllTimeslotsTimeslot = {
  price: number;
  capacity: number;
  reserved: number;
  available: number;
  id: string;
  date: string;
  time: string;
  isPast: boolean;
  utc: string;
  status: number;
};
export type GetAvailableAllTimeslotsDateslot = {
  price: number;
  capacity: number;
  reserved: number;
  available: number;
  id: string;
  date: string;
  rate: number;
  utc: string;
  status: string;
  timeslots: GetAvailableAllTimeslotsTimeslot[];
};
export type GetAvailableAllTimeslotsResult = {
  dateFrom: string;
  dateTo: string;
  dateslots: GetAvailableAllTimeslotsDateslot[];
};
export type GetAvailableAllTimeslotsResponse = {
  code?: number;
  messages?: string[];
  result?: GetAvailableAllTimeslotsResult;
};
export type GetScheduleForSettingsResponseResultProducts = {
  dateslotIds: string[];
  id: string;
  name: string;
  price: number;
  minimumPrice: number;
  maximumPrice: number;
  isExist: boolean;
};
export type GetScheduleForSettingsResponseResultTimeslotsIds = {
  dateslotId: string;
  timeslotIds: string[];
};
export type GetScheduleForSettingsResponseResultTimeslots = {
  ids: GetScheduleForSettingsResponseResultTimeslotsIds[];
  HHmm: string;
  time: string;
  minimumCapacity: number;
  maximumCapacity: number;
  capacity: number;
  isExist: boolean;
};
export type GetScheduleForSettingsResponseResult = {
  weekday: number;
  products: GetScheduleForSettingsResponseResultProducts[];
  timeslots: GetScheduleForSettingsResponseResultTimeslots[];
  isCanCreate: boolean;
  isAllDaySame?: boolean;
  capacity?: number;
};
export type GetScheduleForSettingsResponse = {
  code?: number;
  messages?: string[];
  result?: GetScheduleForSettingsResponseResult[];
};
export type PatchScheduleResponse = {
  code?: number;
  messages?: string[];
};
export type PatchScheduleRequestScheduleProducts = {
  dateslotIds: string[];
  id: string;
  price: number;
};
export type PatchScheduleRequestScheduleTimeslotsIds = {
  dateslotId: string;
  timeslotIds: string[];
};
export type PatchScheduleRequestScheduleTimeslots = {
  ids: PatchScheduleRequestScheduleTimeslotsIds[];
  HHmm: string;
  capacity: number;
};
export type PatchScheduleRequestSchedule = {
  weekday: number;
  products: PatchScheduleRequestScheduleProducts[];
  timeslots: PatchScheduleRequestScheduleTimeslots[];
  isCanCreate: boolean;
  isAllDaySame: boolean;
  capacity: number;
};
export type PatchScheduleRequest = {
  dateFrom: string;
  dateTo: string;
  schedules: PatchScheduleRequestSchedule[];
};
export type DeleteScheduleResponse = {
  code?: number;
  messages?: string[];
};
export type PublishResponse = {
  code?: number;
  messages?: string[];
};
export type PublishRequest = {
  date: string;
};
export type UpdateTimeslotStatusResponse = {
  code?: number;
  messages?: string[];
  result?: GetAvailableAllTimeslotsTimeslot;
};
export type PatchOperation =
  | "add"
  | "replace"
  | "test"
  | "remove"
  | "move"
  | "copy";
export type Patch = {
  op: PatchOperation;
  /** Path */
  path: string;
  /** Value */
  value?: object;
  /** From */
  from?: string;
};
export type PatchRequest = {
  jsonPatch: Patch[];
};
export type CalendarPriceByDayResponseResultDateElement = {
  dateslotId: string;
  date: string;
  utc: string;
  price?: number;
  holidayText?: string;
  status: string;
  isPublished: boolean;
};
export type CalendarPriceByDayResponseResult = {
  dateFrom: string;
  dateTo: string;
  dates: CalendarPriceByDayResponseResultDateElement[];
};
export type CalendarPriceByDayResponse = {
  code?: number;
  messages?: string[];
  result?: CalendarPriceByDayResponseResult;
};
export type AvailableTimeslotsResponseDateslotTimeslot = {
  id: string;
  date: string;
  time: string;
  isPast: boolean;
  status: string;
  utc: string;
  price: number;
  totalAmount: number;
  capacity: number;
  available: number;
};
export type AvailableTimeslotsResponseDateslot = {
  id: string;
  price: number;
  available: number;
  capacity: number;
  date: string;
  utc: string;
  status: string;
  timeslots: AvailableTimeslotsResponseDateslotTimeslot[];
};
export type AvailableTimeslotsResponseResult = {
  dateFrom: string;
  dateTo: string;
  numberOfGuest: number;
  productId: string;
  productName: string;
  dateslots: AvailableTimeslotsResponseDateslot[];
};
export type AvailableTimeslotsResponse = {
  code?: number;
  messages?: string[];
  result?: AvailableTimeslotsResponseResult;
};
export type FirstAvailableTimeslotResponseTimeslot = {
  id: string;
  date: string;
  time: string;
  isPast: boolean;
  utc: string;
  unitPrice: number;
  totalAmount: number;
  capacity: number;
  available: number;
};
export type FirstAvailableTimeslotResponseResult = {
  numberOfGuest: number;
  productId: string;
  productName: string;
  price: number;
  available: number;
  capacity: number;
  date: string;
  utc: string;
  timeslot: FirstAvailableTimeslotResponseTimeslot;
};
export type FirstAvailableTimeslotResponse = {
  code?: number;
  messages?: string[];
  result?: FirstAvailableTimeslotResponseResult;
};
export type CartItem = {
  id: string;
  productId?: string;
  orderId?: string;
  orderNumber?: string;
  quantity?: number;
  meta?: object;
};
export type Cart = {
  id: string;
  items: CartItem[];
};
export type CartResponse = {
  code?: number;
  messages?: string[];
  result?: Cart;
};
export type HeldTimeslotResponseResult = {
  heldTimeslotId: string;
  dateslotId: string;
  timeslotId: string;
  date: string;
  time: string;
  utc: string;
  isPast: boolean;
  price: number;
  totalAmount: number;
  capacity: number;
  available: number;
  numberOfGuest: number;
  expireIn: number;
  expireAt: string;
  isRenewable: boolean;
};
export type HoldTimeslotResponse = {
  code?: number;
  messages?: string[];
  result?: HeldTimeslotResponseResult;
};
export type HeldTimeslotResponse = {
  code?: number;
  messages?: string[];
  result?: HeldTimeslotResponseResult;
};
export type RenewTimeslotResponse = {
  code?: number;
  messages?: string[];
  result?: HeldTimeslotResponseResult;
};
export type ReleaseTimeslotResponseResult = {
  heldTimeslotId: string;
};
export type ReleaseTimeslotResponse = {
  code?: number;
  messages?: string[];
  result?: ReleaseTimeslotResponseResult;
};
export type Timeslot = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  dateslotId: string;
  time: string;
  capacity: number;
  status: number;
};
export type UpdateTimeSlotResponse = {
  code?: number;
  messages?: string[];
  result?: Timeslot;
};
export type ProfileDto = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  firstName: string;
  lastName: string;
  middleName?: string;
  gender?: string;
  dateOfBirth?: string;
  email?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  country?: string;
  fullName: string;
};
export type CustomerDto = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  profileId: string;
  isGuest: boolean;
  phoneNumber1?: string;
  phoneNumber2?: string;
  phoneNumber3?: string;
  membershipNumber?: string;
  membershipType?: number;
  membershipValidFrom?: string;
  membershipValidUntil?: string;
  isOptOutMarketing: boolean;
  isOptOutReminding?: number;
  restrictionType?: number;
  vaultCode?: string;
  legacyId?: string;
  status: number;
  Profile?: ProfileDto;
};
export type Rates = {
  id?: string;
  name?: string;
  taxCode?: string;
  rate: number;
  type: number;
  tipAmount?: number;
  discountAmount?: number;
};
export type OrderLineGiftCertificateMeta = {
  id: string;
  name: string;
  discountAmount: number;
  tipAmount: number;
  isCommission: boolean;
  capacity: number;
};
export type OrderType = 0 | 1 | 2 | 3 | 99;
export type OrderLineMeta = {
  rates?: Rates[];
  giftCertificate?: OrderLineGiftCertificateMeta;
  orderType?: OrderType;
};
export type CustomerAddresses = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  customerId: string;
  type: number;
  recipientName?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  country?: string;
  status: number;
};
export type OrderShippingDto = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  type: number;
  addressId?: string;
  trackingNumber?: string;
  shippingDate?: string;
  senderName?: string;
  senderEmail?: string;
  recipientName?: string;
  recipientEmail?: string;
  pickupFullName?: string;
  pickupDateTime?: string;
  pickupPhone?: string;
  isPickupAgreeTerms?: boolean;
  personalMessage?: string;
  requestMessage?: string;
  status: number;
  CustomerAddress?: CustomerAddresses;
};
export type ProductMeta = {
  templateName?: string;
  expiration?: number;
  followListingPrice?: boolean;
  onlinePriceMethod?: number;
};
export type Product = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  categoryId?: string;
  name: string;
  description?: string;
  gtin?: string;
  brand?: string;
  mpn?: string;
  price: number;
  taxId1?: string;
  taxId2?: string;
  taxId3?: string;
  tipId?: string;
  isCommissionOnRetailSales: boolean;
  isTaxDefault: boolean;
  isCommissionOnRetailSalesDefault: boolean;
  isPrepaidOnly?: boolean;
  tags?: string;
  meta?: ProductMeta;
  status: number;
};
export type Ticket = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  reservationId: string;
  orderLineId?: string;
  status: number;
  refs?: string;
};
export type OrderLinesDto = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  orderId: string;
  sequence?: number;
  categoryId: string;
  productId: string;
  customerId?: string;
  orderShippingId?: string;
  appointmentId?: string;
  salesRepresentativeId?: string;
  listPrice: number;
  sellingPrice: number;
  tax1Amount: number;
  tax2Amount: number;
  extraChargeAmount: number;
  tip1Amount: number;
  tip2Amount: number;
  discount1Amount: number;
  discount2Amount: number;
  discount3Amount: number;
  promotionAmount: number;
  totalAmount: number;
  refundAmount: number;
  status: number;
  orderLineId?: string;
  description?: string;
  meta?: OrderLineMeta;
  productType: number;
  productName?: string;
  OrderShipping?: OrderShippingDto;
  Product?: Product;
  Ticket?: Ticket;
};
export type Reservation = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  productId: string;
  dateslotId: string;
  timeslotId: string;
  numberOfGuest: number;
  orderId?: string;
  status: number;
  refs?: string;
  Timeslot?: Timeslot;
};
export type PaymentLineMetaResponseDataChargePaymentMethodTransactionRiskData =
  {
    id?: string;
    decision?: string;
  };
export type PaymentLineMetaResponseDataChargePaymentMethodTransaction = {
  status?: string;
  riskData?: PaymentLineMetaResponseDataChargePaymentMethodTransactionRiskData;
};
export type PaymentLineMetaResponseDataChargePaymentMethod = {
  transaction?: PaymentLineMetaResponseDataChargePaymentMethodTransaction;
};
export type PaymentLineMetaResponseDataReverseTransactionReversal = {
  id?: string;
  status?: string;
};
export type PaymentLineMetaResponseDataReverseTransaction = {
  reversal?: PaymentLineMetaResponseDataReverseTransactionReversal;
};
export type PaymentLineMetaResponseData = {
  chargePaymentMethod?: PaymentLineMetaResponseDataChargePaymentMethod;
  reverseTransaction?: PaymentLineMetaResponseDataReverseTransaction;
};
export type PaymentLineMetaResponse = {
  data?: PaymentLineMetaResponseData;
};
export type PaymentLineMeta = {
  response?: PaymentLineMetaResponse;
};
export type PaymentLines = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  orderId: string;
  type: number;
  tenderType: number;
  label?: string;
  grossAmount: number;
  adjustmentAmount: number;
  tipAmount: number;
  taxAmount: number;
  requestAmount: number;
  uniqueCode?: string;
  approvedAmount: number;
  customerId?: string;
  reason?: string;
  relationId?: string;
  status: number;
  cardHolderName?: string;
  billingAddress?: string;
  paymentLineId?: string;
  description?: string;
  meta?: PaymentLineMeta;
  customerName?: string;
};
export type Channel = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  orderNumber: string;
  paymentNumber?: string;
  legacyNumber?: string;
};
export type IncompleteOrderDto = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  channelId?: string;
  endOfDate?: string;
  orderNumber: string;
  status: number;
  deviceId: string;
  cashierId: string;
  cashierName: string;
  customerId?: string;
  receiptEmail?: string;
  amount: number;
  tax1Amount: number;
  tax2Amount: number;
  extraChargeAmount: number;
  tip1Amount: number;
  tip2Amount: number;
  discount1Amount: number;
  discount2Amount: number;
  discount3Amount: number;
  shippingAmount: number;
  totalAmount: number;
  paidAmount: number;
  refundAmount: number;
  refundShippingAmount: number;
  orderId?: string;
  ipAddress?: string;
  description?: string;
  completedAt?: string;
  refundedAt?: string;
  meta?: object;
  updatedByName: string;
  lineCount?: number;
  lockerNumbers?: string;
  tenderCount?: number;
  tenders?: string;
  Customer?: CustomerDto;
  OrderLines?: OrderLinesDto[];
  Reservation?: Reservation;
  PaymentLines?: PaymentLines[];
  Channel?: Channel;
  dueAtServiceAmount?: number;
  dueNowAmount?: number;
  restrictionType?: number;
};
export type IncompleteOrderResponse = {
  code?: number;
  messages?: string[];
  result?: IncompleteOrderDto;
};
export type RemindingType = 0 | 1 | 2 | 3;
export type CreateIncompleteOrderRequestContact = {
  id?: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  isOptOutMarketing?: boolean;
  isOptOutReminding?: RemindingType;
};
export type DeliveryMethod = 1 | 2 | 3;
export type CreateIncompleteOrderRequestDeliveryShippingAddress = {
  city: string;
  state: string;
  zipcode: string;
  address: string;
};
export type CreateIncompleteOrderRequestDeliveryPickup = {
  fullName: string;
  phone: string;
  dateTime: string;
};
export type CreateIncompleteOrderRequestDelivery = {
  deliveryMethod: DeliveryMethod;
  emailTo: string;
  shippingAddress?: CreateIncompleteOrderRequestDeliveryShippingAddress;
  pickup?: CreateIncompleteOrderRequestDeliveryPickup;
  nameFrom: string;
  nameTo: string;
  shippingFullName?: string;
  personalMessage?: string;
};
export type CreateIncompleteOrderForReservationAdmissionRequest = {
  customer?: CreateIncompleteOrderRequestContact;
  delivery?: CreateIncompleteOrderRequestDelivery;
  orderType?: OrderType;
  heldTimeslotId: string;
};
export type UpdateIncompleteOrderRequest = {
  customer: CreateIncompleteOrderRequestContact;
  delivery: CreateIncompleteOrderRequestDelivery;
  orderType?: OrderType;
};
export type ConfirmOrderDto = {
  id: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  channelId?: string;
  endOfDate?: string;
  orderNumber: string;
  status: number;
  deviceId: string;
  cashierId: string;
  cashierName: string;
  customerId?: string;
  receiptEmail?: string;
  amount: number;
  tax1Amount: number;
  tax2Amount: number;
  extraChargeAmount: number;
  tip1Amount: number;
  tip2Amount: number;
  discount1Amount: number;
  discount2Amount: number;
  discount3Amount: number;
  shippingAmount: number;
  totalAmount: number;
  paidAmount: number;
  refundAmount: number;
  refundShippingAmount: number;
  orderId?: string;
  ipAddress?: string;
  description?: string;
  completedAt?: string;
  refundedAt?: string;
  meta?: object;
  updatedByName: string;
  lineCount?: number;
  lockerNumbers?: string;
  tenderCount?: number;
  tenders?: string;
  Customer?: CustomerDto;
  OrderLines?: OrderLinesDto[];
  Reservation?: Reservation;
  PaymentLines?: PaymentLines[];
  Channel?: Channel;
  dueAtServiceAmount?: number;
  dueNowAmount?: number;
  restrictionType?: number;
};
export type ConfirmOrderResponse = {
  code?: number;
  messages?: string[];
  result?: ConfirmOrderDto;
};
export type ConfirmOrderRequestBillingAddress = {
  city: string;
  state: string;
  zipcode: string;
  address: string;
};
export type TenderType = 0 | 1 | 2 | 3 | 4 | 11 | 12 | 13 | 14 | 15;
export type ConfirmOrderRequestPayment = {
  billingAddress?: ConfirmOrderRequestBillingAddress;
  tenderType: TenderType;
  deviceData?: string | null;
  /** 기존의 텐더를 사용할 때 PaymentLineId를 UniqueCode로 사용. */
  uniqueCode: string;
  isSkip?: boolean;
};
export type ConfirmOrderRequest = {
  payment: ConfirmOrderRequestPayment;
  orderType?: OrderType;
  promotionCode?: string;
};
export const {
  useGetProductWithPriceByDateQuery,
  useGetAvailableAllTimeslotsQuery,
  useGetScheduleForSettingsQuery,
  usePatchScheduleMutation,
  useDeleteScheduleMutation,
  usePublishMutation,
  usePatchTimeslotMutation,
  useCalendarPriceByDayQuery,
  useGetAvailableTimeslotsQuery,
  useGetFirstAvailableTimeslotQuery,
  useGetCartForReservationAdmissionQuery,
  useHoldTimeslotMutation,
  useGetHeldTimeslotQuery,
  useRenewTimeslotMutation,
  useReleaseTimeslotMutation,
  usePatchReservationAdmissionMutation,
  useCreateIncompleteOrderForReservationAdmissionMutation,
  useUpdateIncompleteOrderForReservationAdmissionMutation,
  useConfirmOrderForReservationAdmissionMutation,
} = injectedRtkApi;

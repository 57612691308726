import { emptySplitApi as api } from "./emptyApi";
export const addTagTypes = [
  "checkPasswordHash",
  "changePassword",
  "login",
  "logout",
  "me",
  "validatePasswordResetToken",
  "sendPasswordResetLink",
  "resetPassword",
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      checkPasswordHash: build.query<
        CheckPasswordHashApiResponse,
        CheckPasswordHashApiArg
      >({
        query: () => ({ url: `/auth/checkPasswordHash` }),
        providesTags: ["checkPasswordHash"],
      }),
      changePassword: build.mutation<
        ChangePasswordApiResponse,
        ChangePasswordApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/changePassword`,
          method: "PATCH",
          data: queryArg.passwordReset,
        }),
        invalidatesTags: ["changePassword"],
      }),
      login: build.mutation<LoginApiResponse, LoginApiArg>({
        query: (queryArg) => ({
          url: `/auth/login`,
          method: "POST",
          data: queryArg.login,
        }),
        invalidatesTags: ["login"],
      }),
      logout: build.mutation<LogoutApiResponse, LogoutApiArg>({
        query: () => ({ url: `/auth/logout`, method: "POST" }),
        invalidatesTags: ["logout"],
      }),
      me: build.query<MeApiResponse, MeApiArg>({
        query: () => ({ url: `/auth/me` }),
        providesTags: ["me"],
      }),
      validatePasswordResetToken: build.query<
        ValidatePasswordResetTokenApiResponse,
        ValidatePasswordResetTokenApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/password/validatePasswordResetToken/${queryArg.token}`,
        }),
        providesTags: ["validatePasswordResetToken"],
      }),
      sendPasswordResetLink: build.mutation<
        SendPasswordResetLinkApiResponse,
        SendPasswordResetLinkApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/password/resetLink`,
          method: "POST",
          data: queryArg.resetPasswordLinkRequest,
        }),
        invalidatesTags: ["sendPasswordResetLink"],
      }),
      resetPassword: build.mutation<
        ResetPasswordApiResponse,
        ResetPasswordApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/password/resetPassword`,
          method: "PATCH",
          data: queryArg.passwordRequest,
        }),
        invalidatesTags: ["resetPassword"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as edgeAuthApi };
export type CheckPasswordHashApiResponse = /** status 200  */ boolean;
export type CheckPasswordHashApiArg = void;
export type ChangePasswordApiResponse = /** status 200  */ Jwt;
export type ChangePasswordApiArg = {
  passwordReset: PasswordReset;
};
export type LoginApiResponse = /** status 200  */ Jwt;
export type LoginApiArg = {
  login: Login;
};
export type LogoutApiResponse = unknown;
export type LogoutApiArg = void;
export type MeApiResponse = /** status 200  */ User;
export type MeApiArg = void;
export type ValidatePasswordResetTokenApiResponse =
  /** status 200  */ ValidateResetPasswordLinkResponse;
export type ValidatePasswordResetTokenApiArg = {
  token: string;
};
export type SendPasswordResetLinkApiResponse = /** status 200  */ VoidResponse;
export type SendPasswordResetLinkApiArg = {
  resetPasswordLinkRequest: ResetPasswordLinkRequest;
};
export type ResetPasswordApiResponse = /** status 200  */ VoidResponse;
export type ResetPasswordApiArg = {
  passwordRequest: PasswordRequest;
};
export type Jwt = {
  token_type: string;
  expires_in: number;
  access_token: string;
  refresh_token: string;
};
export type PasswordReset = {
  oldPassword?: string;
  newPassword: string;
};
export type Login = {
  username: string;
  password: string;
};
export type Profile = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
};
export type Employment = {
  id: string;
  phoneNumber: string;
  employeeNumber: string;
  departmentId: string;
  roleId: string;
};
export type Customer = {
  id: string;
  profileId: string;
  phoneNumber1: string;
  phoneNumber2: string;
  phoneNumber3: string;
  isOptOutMarketing: boolean;
  isOptOutReminding: number;
};
export type User = {
  id: string;
  username: string;
  status: number;
  lastLoggedAt: string;
  Profile: Profile;
  Employment: Employment;
  Customer: Customer;
  role: string;
  avatar: string;
};
export type ResetTokenAndUserName = {
  name: string;
  token: string;
};
export type ValidateResetPasswordLinkResponse = {
  code?: number;
  messages?: string[];
  result?: ResetTokenAndUserName;
};
export type VoidResponse = {
  code?: number;
  messages?: string[];
};
export type ResetPasswordLinkRequest = {
  email: string;
};
export type PasswordRequest = {
  token: string;
  password: string;
};
export const {
  useCheckPasswordHashQuery,
  useChangePasswordMutation,
  useLoginMutation,
  useLogoutMutation,
  useMeQuery,
  useValidatePasswordResetTokenQuery,
  useSendPasswordResetLinkMutation,
  useResetPasswordMutation,
} = injectedRtkApi;

import { emptySplitApi as api } from "./emptyApi";
export const addTagTypes = ["getTemplate", "createTemplate"] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getTemplate: build.query<GetTemplateApiResponse, GetTemplateApiArg>({
        query: (queryArg) => ({ url: `/template/getTemplate/${queryArg.key}` }),
        providesTags: ["getTemplate"],
      }),
      createTemplate: build.mutation<
        CreateTemplateApiResponse,
        CreateTemplateApiArg
      >({
        query: (queryArg) => ({
          url: `/template/createTemplate`,
          method: "POST",
          data: queryArg.createTemplateRequest,
        }),
        invalidatesTags: ["createTemplate"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as edgeTemplateApi };
export type GetTemplateApiResponse = /** status 200  */ GetTemplateResponse;
export type GetTemplateApiArg = {
  key: string;
};
export type CreateTemplateApiResponse =
  /** status 200  */ CreateTemplateResponse;
export type CreateTemplateApiArg = {
  createTemplateRequest: CreateTemplateRequest;
};
export type GetTemplateResponse = {
  code: number;
  messages: string[];
  result: string;
};
export type CreateTemplateResponse = {
  code: number;
  messages: string[];
};
export type CreateTemplateRequest = {
  key: string;
  template: string;
};
export const { useGetTemplateQuery, useCreateTemplateMutation } =
  injectedRtkApi;

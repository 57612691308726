import { ConfirmationOptions, createErrorToastOptionByApplicationError } from '..';
import { createErrorOptionsByApplicationError, createErrorOptionsByServerStatusError } from '..';
import { BaseQueryFn, MutationActionCreatorResult, MutationDefinition, QueryActionCreatorResult, QueryDefinition } from '@reduxjs/toolkit/query';

export type RestrictionErrorMessageArgs = { restrictionType: string; fullName: string; phoneNumber: string; email: string; note: string };
const getRestrictionErrorMessage = (messages: string[]) => {
  const banOrWatchListValidation = messages[0].split('|');
  const restrictionType = banOrWatchListValidation[0];
  const fullName = banOrWatchListValidation[1];
  const phoneNumber = banOrWatchListValidation[2];
  const email = banOrWatchListValidation[3];
  const note = banOrWatchListValidation[4];
  return { restrictionType, fullName, phoneNumber, email, note };
};
export const doQuery = async <TApiArg, TTagTypes extends string, TResultType extends { code?: number; messages?: string[] }, D extends QueryDefinition<TApiArg, BaseQueryFn, any, TResultType>>(
  queryOrMutation: MutationActionCreatorResult<MutationDefinition<TApiArg, BaseQueryFn, TTagTypes, TResultType>> | QueryActionCreatorResult<D>,
  onHandle?: {
    onSuccess?: (res?: TResultType) => Promise<void>;
    onFailed?: (code: number) => Promise<void>;
    confirm?: (confirmationOptions: ConfirmationOptions) => Promise<void>;
    onRestrictionError?: (args: RestrictionErrorMessageArgs) => Promise<void>;
  },
): Promise<TResultType> => {
  const onSuccess = onHandle?.onSuccess;
  const onFailed = onHandle?.onFailed;
  const confirm = onHandle?.confirm;
  const onRestrictionError = onHandle?.onRestrictionError;

  const doOnFailedIfExist = async (codeOrStatus: number) => {
    if (onFailed) await onFailed(codeOrStatus);
  };
  try {
    const response = await queryOrMutation.unwrap();
    if (response.code == 1) {
      if (onSuccess) await onSuccess(response);
      return response;
    } else if (response.code == 734) {
      const { restrictionType, fullName, phoneNumber, email, note } = getRestrictionErrorMessage(response.messages);
      onRestrictionError && onRestrictionError({ restrictionType, fullName, phoneNumber, email, note });
    } else {
      confirm ? await confirm(createErrorOptionsByApplicationError(response)).catch(() => doOnFailedIfExist(response.code)) : doOnFailedIfExist(response.code);
    }
  } catch (error) {
    switch (error.status) {
      case 500:
        document.location.href = '/500';
        break;
      default:
        confirm ? await confirm(createErrorOptionsByServerStatusError(error)).catch(() => doOnFailedIfExist(error.status)) : doOnFailedIfExist(error.status);
        break;
    }
    throw error;
  }
};

// export const useDoRtkQueryOrMutation = () => {
//   const doRtkQueryOrMutation = async <TApiArg, TTagTypes extends string, TResultType extends { code?: number; messages?: string[] }, D extends QueryDefinition<TApiArg, BaseQueryFn, any, TResultType>>(
//     queryOrMutation: (MutationActionCreatorResult<MutationDefinition<TApiArg, BaseQueryFn, TTagTypes, TResultType>> | QueryActionCreatorResult<D>),
//     onHandle?: {
//       onSuccess?: (res?: TResultType) => Promise<void>;
//       onFailed?: (code: number) => Promise<void>;
//       confirm?: (confirmationOptions: ConfirmationOptions) => Promise<void>;
//     },
//   ): Promise<TResultType> => {
//     const onSuccess = onHandle?.onSuccess;
//     const onFailed = onHandle?.onFailed;
//     const confirm = onHandle?.confirm;
//     try {
//       const response = await queryOrMutation.unwrap();
//       if (response.code == 1) {
//         if (onSuccess) await onSuccess(response);
//         return response;
//       } else {
//         // TODO: code 4-500대와 message가 나오는 지 확인
//         console.log('response code is not 1!');
//         const doOnFailed = onFailed ? async () => await onFailed(response.code) : null;
//         if(confirm){
//           await confirm(createErrorOptions(response)).catch(doOnFailed);
//         }else{
//           doOnFailed;
//         }
//       }
//     } catch (error) {
//       switch (error.status) {
//         case 500:
//           document.location.replace('/500');
//           break;
//         default:
//           await confirm(createErrorOptions(error)).catch(async () => {
//             if(onFailed) await onFailed(error.status);
//           });
//           break;
//       }
//       throw error;
//     }
//   };
//   return doRtkQueryOrMutation;
// };

import { emptySplitApi as api } from "./emptyApi";
export const addTagTypes = ["getEcho", "raw"] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getEcho: build.query<GetEchoApiResponse, GetEchoApiArg>({
        query: () => ({ url: `/report/echo` }),
        providesTags: ["getEcho"],
      }),
      raw: build.query<RawApiResponse, RawApiArg>({
        query: (queryArg) => ({
          url: `/report/raw/${queryArg.name}`,
          params: { params: queryArg.params },
        }),
        providesTags: ["raw"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as edgeReportApi };
export type GetEchoApiResponse = unknown;
export type GetEchoApiArg = void;
export type RawApiResponse = /** status 200  */ ObjectResponse;
export type RawApiArg = {
  params?: string[];
  name: string;
};
export type ObjectResponse = {
  code?: number;
  messages?: string[];
  result?: string[];
};
export const { useGetEchoQuery, useRawQuery } = injectedRtkApi;

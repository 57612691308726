import { emptySplitApi as api } from "./emptyApi";
export const addTagTypes = [
  "getByEmail",
  "getByPhoneNumber",
  "status",
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getByEmail: build.query<GetByEmailApiResponse, GetByEmailApiArg>({
        query: (queryArg) => ({
          url: `/customer/byEmail`,
          params: { email: queryArg.email },
        }),
        providesTags: ["getByEmail"],
      }),
      getByPhoneNumber: build.query<
        GetByPhoneNumberApiResponse,
        GetByPhoneNumberApiArg
      >({
        query: (queryArg) => ({
          url: `/customer/byPhoneNumber`,
          params: { phoneNumber: queryArg.phoneNumber },
        }),
        providesTags: ["getByPhoneNumber"],
      }),
      status: build.query<StatusApiResponse, StatusApiArg>({
        query: (queryArg) => ({ url: `/customer/status/${queryArg.id}` }),
        providesTags: ["status"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as edgeCustomerApi };
export type GetByEmailApiResponse = /** status 200  */ string[];
export type GetByEmailApiArg = {
  /** email address */
  email?: any;
};
export type GetByPhoneNumberApiResponse = /** status 200  */ string[];
export type GetByPhoneNumberApiArg = {
  /** phone number */
  phoneNumber: any;
};
export type StatusApiResponse = /** status 200  */ VoidResponse;
export type StatusApiArg = {
  id: string;
};
export type VoidResponse = {
  code?: number;
  messages?: string[];
};
export const { useGetByEmailQuery, useGetByPhoneNumberQuery, useStatusQuery } =
  injectedRtkApi;
